import React, { Component } from 'react'
import TopBar from '../components/TopBar';
import NavBar from '../components/NavBar';
import Breadcrumbs from '../components/Breadcrumbs';
import PengurusBesar from '../components/PengurusBesar';
import PengurusBidangPembinaanAnggota from '../components/PengurusBidangPembinaanAnggota';
import PengurusBidangSosialMasyarakat from '../components/PengurusBidangSosialMasyarakat';
import PengurusBidangKeagamaan from '../components/PengurusBidangKeagamaan';
import PengurusBidangSeniOlahraga from '../components/PengurusBidangSeniOlahraga';
import PengurusBidangPendidikan from '../components/PengurusBidangPendidikan';
import PengurusBidangKomunikasiInformasi from '../components/PengurusBidangKomunikasiInformasi';
import Footer from '../components/Footer';

class SemuaPengurus extends Component {

  componentDidMount(){
    window.scroll(0,0)
  }
  
  render() {
    return (
        <div>
            <TopBar />
            <NavBar title="Pengurus | IPMSM" />
            <Breadcrumbs pagetitle="Pengurus" pagesubtitle="Susunan Pengurus Ikatan Pelajar Mahasiswa Sungai Mandau Periode 2022-2024" />
            <PengurusBesar />
            <PengurusBidangPembinaanAnggota />
            <PengurusBidangSosialMasyarakat />
            <PengurusBidangKeagamaan />
            <PengurusBidangSeniOlahraga />
            <PengurusBidangPendidikan />
            <PengurusBidangKomunikasiInformasi />
            <Footer />
        </div>
    )
  }
}

export default SemuaPengurus