import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

class NotFound extends Component {
  render() {
    return (
        <div>
            <div id="notfound">
                <div class="notfound">
                    <div class="notfound-404">
                        <h1>Oops!</h1>
                        <h2>404 - Halaman tidak ditemukan</h2>
                    </div>
                    <NavLink to="/">Kembali ke Beranda</NavLink>
                </div>
            </div>
        </div>
    )
  }
}

export default NotFound