import React, { Component } from 'react'
import { Route, Routes } from 'react-router-dom'
import Beranda from '../pages/Beranda'
import ProfilLengkap from '../pages/ProfilLengkap'
import SemuaProker from '../pages/SemuaProker'
import SemuaPengurus from '../pages/SemuaPengurus'
import BeritaLengkap from '../pages/BeritaLengkap'
import BeritaDetail from '../pages/BeritaDetail'
import KontakKami from '../pages/KontakKami'
import PageNotFound from '../pages/PageNotFound'

class AppRouter extends Component {
  render() {
    return (
        <Routes>
            <Route path='/' element={<Beranda />} />
            <Route path='/profil' element={<ProfilLengkap />} />
            <Route path='/program-kerja' element={<SemuaProker />} />
            <Route path='/pengurus' element={<SemuaPengurus />} />
            <Route path='/berita' element={<BeritaLengkap />} />
            <Route path='/berita-selengkapnya/:beritaID' element={<BeritaDetail />} />
            <Route path='/kontak' element={<KontakKami />} />
            <Route path='*' element={<PageNotFound />} />
        </Routes>
    )
  }
}

export default AppRouter