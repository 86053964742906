import React, { Component } from 'react'
import RestClient from '../RestAPI/RestClient';
import AppUrl from '../RestAPI/AppUrl';

class Kontak extends Component {

    kirimPesan = (event) => {
        event.preventDefault();
    
        let name = document.getElementById("name").value;
        let email = document.getElementById("email").value;
        let subject = document.getElementById("subject").value;
        let message = document.getElementById("message").value;
    
        // Jika ada bidang yang kosong, kembalikan fokus ke bidang pertama
        if (!name || !email || !subject || !message) {
            document.getElementById("name").focus();
            return;
        }
    
        let jsonObject = {name:name,email:email,subject:subject,message:message}
        RestClient.PostRequest(AppUrl.Kontak, JSON.stringify(jsonObject))
            .then(result => {
                if (result === 1) {
                    // Tampilkan notifikasi sukses jika pesan berhasil dikirim
                    document.querySelector('.sent-message').style.display = 'block';
                    setTimeout(() => {
                        document.querySelector('.sent-message').style.display = 'none';
                    }, 3000);
                    // Set ulang nilai-nilai input menjadi string kosong
                    document.getElementById("name").value = "";
                    document.getElementById("email").value = "";
                    document.getElementById("subject").value = "";
                    document.getElementById("message").value = "";
                } else {
                    // Tampilkan notifikasi gagal jika pesan tidak berhasil dikirim
                    document.querySelector('.error-message').style.display = 'block';
                    setTimeout(() => {
                        document.querySelector('.error-message').style.display = 'none';
                    }, 3000);
                }
            })
            .catch(error => {
                // Tampilkan notifikasi kesalahan jika terjadi kesalahan saat mengirim pesan
                document.querySelector('.error-message').style.display = 'block';
                setTimeout(() => {
                    document.querySelector('.error-message').style.display = 'none';
                }, 3000);
            });
    }    
    
  render() {
    return (
        <section id="kontak" class="contact">
            <div class="container" data-aos="fade-up">
                <div class="section-header">
                    <h2>Kontak</h2>
                    <p>Jika ada pertanyaan, kritik dan saran. Silahkan hubungi kami dibawah ini.</p>
                </div>
                <div class="row gx-lg-0 gy-4">
                    <div class="col-lg-4">
                        <div class="info-container d-flex flex-column align-items-center justify-content-center">
                        <div class="info-item d-flex">
                            <i class="bi bi-geo-alt flex-shrink-0"></i>
                            <div>
                            <h4>Sekretariat:</h4>
                            <p>Jl. Melati Sakti Perumahan Setia Mulia III Pengembangan Blok D6 Pekanbaru</p>
                            </div>
                        </div>
                        <div class="info-item d-flex">
                            <i class="bi bi-envelope flex-shrink-0"></i>
                            <div>
                            <h4>Email:</h4>
                            <p>ipmsmsungaimandau@gmail.com</p>
                            </div>
                        </div>
                        <div class="info-item d-flex">
                            <i class="bi bi-phone flex-shrink-0"></i>
                            <div>
                            <h4>Telp/Wa:</h4>
                            <p>082289917296</p>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <form class="php-email-form">
                        <div class="row">
                            <div class="col-md-6 form-group">
                            <input type="text" class="form-control" id="name" placeholder="Nama Anda" required />
                            </div>
                            <div class="col-md-6 form-group mt-3 mt-md-0">
                            <input type="email" class="form-control" id="email" placeholder="Email Anda" required />
                            </div>
                        </div>
                        <div class="form-group mt-3">
                            <input type="text" class="form-control" id="subject" placeholder="Subjek" required />
                        </div>
                        <div class="form-group mt-3">
                            <textarea class="form-control" id="message" rows="7" placeholder="Pesan" required></textarea>
                        </div>
                        <div class="my-3">
                            <div class="loading">Loading</div>
                            <div class="error-message"></div>
                            <div class="sent-message">Pesan Anda telah terkirim. Terima kasih!</div>
                        </div>
                        <div class="text-center"><button onClick={this.kirimPesan} class="tombol-kirim-pesan">Kirim Pesan</button></div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
  }
}

export default Kontak