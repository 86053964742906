import React, { Component } from 'react'
import TopBar from '../components/TopBar'
import NavBar from '../components/NavBar'
import Breadcrumbs from '../components/Breadcrumbs'
import SemuaBerita from '../components/SemuaBerita'
import Footer from '../components/Footer'

class BeritaLengkap extends Component {

  componentDidMount(){
    window.scroll(0,0)
  }
  
  render() {
    return (
        <div>
            <TopBar />
            <NavBar title="Berita | IPMSM" />
            <Breadcrumbs pagetitle="Berita" pagesubtitle="Semua Berita Tentang Ikatan Pelajar Mahasiswa Sungai Mandau" />
            <SemuaBerita />
            <Footer />
        </div>
    )
  }
}

export default BeritaLengkap