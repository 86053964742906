import React, { Component } from 'react'
import CountUp from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'
import RestClient from '../RestAPI/RestClient';
import AppUrl from '../RestAPI/AppUrl';
import Loading from './Loading';
import WentWrong from './WentWrong';

class Summary extends Component {

    constructor(){
        super();
        this.state={
            image:"",
            total_anggota_aktif:"",
            total_alumni:"",
            loading:true,
            error:false
        }
    }
    
    componentDidMount(){
        RestClient.GetRequest(AppUrl.Summary).then(result=>{

            if(result == null){
                this.setState({error:true,loading:false})
            }else{
                this.setState({
                    image:result[0]['summary_image'],
                    total_anggota_aktif:result[0]['summary_anggota_aktif'],
                    total_alumni:result[0]['summary_alumni'],
                    loading:false
                });
            }
        }).catch(error=>{
            this.setState({error:true})
        })
    }

  render() {

    if (this.state.loading == true) {
        return <Loading />
    }
    else if (this.state.error == false) {
        return (
            <section id="stats-counter" class="stats-counter">
                <div class="container" data-aos="fade-up">
                    <div class="row gy-4 align-items-center">
                        <div class="col-lg-6">
                            <img src={this.state.image} alt="" class="img-fluid" />
                        </div>
                        <div class="col-lg-6">
                            <div class="stats-item d-flex align-items-center">
                                <span className="purecounter mr-5">
                                    <CountUp start={0} end={this.state.total_anggota_aktif}>
                                    {({ countUpRef, start }) => (
                                        <VisibilitySensor onChange={start} delayedCall>
                                            <span ref={countUpRef} />
                                        </VisibilitySensor>
                                    )}
                                    </CountUp>
                                </span>
                                <p><strong>Anggota Aktif</strong> Ikatan Pelajar Mahasiswa Sungai Mandau</p>
                            </div>
                            <div class="stats-item d-flex align-items-center">
                                <span className="purecounter">
                                    <CountUp start={0} end={this.state.total_alumni}>
                                    {({ countUpRef, start }) => (
                                        <VisibilitySensor onChange={start} delayedCall>
                                            <span ref={countUpRef} />
                                        </VisibilitySensor>
                                    )}
                                    </CountUp>
                                </span>
                                <p><strong>Alumni</strong> Ikatan Pelajar Mahasiswa Sungai Mandau</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    } // end else
    else if (this.state.error == true) {
        return <WentWrong />
    } // end if for error
  }
}

export default Summary