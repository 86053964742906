import React, { Component } from 'react'
import RestClient from '../RestAPI/RestClient';
import AppUrl from '../RestAPI/AppUrl';
import HTMLReactParser from 'html-react-parser';
import Loading from './Loading';
import WentWrong from './WentWrong';

class Profil extends Component {

    constructor(){
        super();
        this.state={
            image:"",
            description_one:"",
            description_two:"",
            video_background:"",
            video_link:"",
            loading:true,
            error:false
        }
    }
    
    componentDidMount(){
        RestClient.GetRequest(AppUrl.Profil).then(result=>{

            if(result == null){
                this.setState({error:true,loading:false})
            }else{
                this.setState({
                    image:result[0]['profil_image'],
                    description_one:result[0]['profil_description_one'],
                    description_two:result[0]['profil_description_two'],
                    video_background:result[0]['profil_video_background'],
                    video_link:result[0]['profil_video_link'],
                    loading:false
                });
            }
        }).catch(error=>{
            this.setState({error:true})
        })
    }

  render() {

    if (this.state.loading == true) {
        return <Loading />
    }
    else if (this.state.error == false) {
        return (
            <section id="profil" class="about">
                <div class="container" data-aos="fade-up">
                    <div class="section-header">
                        <h2>Profil</h2>
                        <p>Sekilas Tentang Ikatan Pelajar Mahasiswa Sungai Mandau</p>
                    </div>
                    <div class="row gy-4">
                        <div class="col-lg-6">
                            <img src={this.state.image} class="img-fluid rounded-4 mb-4" alt="" />
                            <p>
                                {HTMLReactParser(this.state.description_one)}
                            </p>
                        </div>
                        <div class="col-lg-6">
                            <div class="content ps-0 ps-lg-5">
                            <p>
                                {HTMLReactParser(this.state.description_two)}
                            </p>
    
                            <div class="position-relative mt-4">
                                <img src={this.state.video_background} class="img-fluid rounded-4" alt="" />
                                <a href={this.state.video_link} class="glightbox play-btn"></a>
                            </div>
                            </div>
                        </div>
                    </div>
    
                </div>
            </section>
        )
    } // end else
    else if (this.state.error == true) {
        return <WentWrong />
    } // end if for error
  }
}

export default Profil