import React, { Component } from 'react'
import TopBar from '../components/TopBar'
import NavBar from '../components/NavBar'
import NotFound from '../components/NotFound'
import Footer from '../components/Footer'

class PageNotFound extends Component {
  render() {
    return (
        <div>
            <TopBar />
            <NavBar title="404 - Halaman Tidak Ditemukan" />
            <NotFound />
            <Footer />
        </div>
    )
  }
}

export default PageNotFound