import React, { Component } from 'react'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import RestClient from '../RestAPI/RestClient';
import AppUrl from '../RestAPI/AppUrl';
import Loading from './Loading';
import WentWrong from './WentWrong';

class KataAlumni extends Component {

    constructor(){
        super();
        this.state={
            myData:[],
            loading:true,
            error:false
        }
    }
    
    componentDidMount(){
        RestClient.GetRequest(AppUrl.KataAlumni).then(result=>{

          if(result == null){
            this.setState({error:true,loading:false})
        }else{
          this.setState({myData:result,loading:false});
        }
        }).catch(error=>{
          this.setState({error:true})
      })
    }

  render() {

    if (this.state.loading == true) {
      return <Loading />
  }
  else if (this.state.error == false) {
    const MyList = this.state.myData;
    const MyView = MyList.map(MyList=>{
        return <div class="swiper-slide">
                    <div class="testimonial-wrap">
                        <div class="testimonial-item">
                        <div class="d-flex align-items-center">
                            <img src={MyList.kata_alumni_image} class="testimonial-img flex-shrink-0" alt="" />
                            <div>
                            <h3>{MyList.kata_alumni_name}</h3>
                            <h4>{MyList.kata_alumni_angkatan}</h4>
                            </div>
                        </div>
                        <p>
                            <i class="bi bi-quote quote-icon-left"></i>
                                {MyList.kata_alumni_description}
                            <i class="bi bi-quote quote-icon-right"></i>
                        </p>
                        </div>
                    </div>
                </div>
    })

    var settings = {
        autoplaySpeed:3000,
        autoplay:true,
        arrows:false,
        dots: true,
        infinite: true,
        speed: 3000,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    };
    
    return (
        <section id="testimonials" class="testimonials">
            <div class="container" data-aos="fade-up">
                <div class="section-header">
                    <h2>Kata Alumni</h2>
                    <p>Beberapa Kata Alumni Tentang Ikatan Pelajar Mahasiswa Sungai Mandau</p>
                </div>

                <div class="slides-3 swiper" data-aos="fade-up" data-aos-delay="100">
                    <Slider {...settings}>
                        {MyView}
                    </Slider>
                    <div class="swiper-pagination"></div>
                </div>
            </div>
        </section>
    )
  } // end else
  else if (this.state.error == true) {
    return <WentWrong />
  } // end if for error
  }
}

export default KataAlumni