import React, { Component } from 'react';
import HTMLReactParser from 'html-react-parser';
import { format } from 'date-fns';
import { NavLink } from 'react-router-dom';
import AppUrl from '../RestAPI/AppUrl';
import RestClient from '../RestAPI/RestClient';
import Loading from './Loading';
import WentWrong from './WentWrong';

class DetailBerita extends Component {
    constructor(props) {
        super(props);
        this.state = {
            myData: [],
            loading:true,
            error:false
        };
    }

    componentDidMount() {
        RestClient.GetRequest(AppUrl.BeritaAll).then(result => {

            if(result == null){
                this.setState({error:true,loading:false})
            }else{
                this.setState({ myData: result,loading:false });
            }
        }).catch(error=>{
            this.setState({error:true})
        })
    }

    render() {

        if (this.state.loading == true) {
            return <Loading />
        }
        else if (this.state.error == false) {
            const BeritaSelengkapnyaArray = this.props.beritaallData;
            let BeritaImage = '';
            let BeritaTitle = '';
            let BeritaDescription = '';
            let CreatedAt = '';
            let tanggalFormat = '';

            if (BeritaSelengkapnyaArray.length === 1) {
                BeritaImage = BeritaSelengkapnyaArray[0]['berita_image'];
                BeritaTitle = BeritaSelengkapnyaArray[0]['berita_title'];
                BeritaDescription = BeritaSelengkapnyaArray[0]['berita_description'];
                CreatedAt = BeritaSelengkapnyaArray[0]['created_at'];

                tanggalFormat = format(new Date(CreatedAt), 'dd MMMM yyyy');
            }

            const MyList = this.state.myData.slice(0, 7); // Memotong array untuk membatasi hanya 7 berita
            const currentBeritaId = BeritaSelengkapnyaArray.length === 1 ? BeritaSelengkapnyaArray[0].id : null; // Dapatkan ID berita saat ini

            const MyView = MyList.map(MyList => {
                if (MyList.id !== currentBeritaId) { // Pastikan berita saat ini tidak ditampilkan di daftar "Berita Lainnya"
                    const tanggalFormat = format(new Date(MyList.created_at), 'dd MMMM yyyy');
                    return (
                        <div className="post-item mt-3" key={MyList.id}>
                            <img src={MyList.berita_image} alt="" />
                            <div>
                                <h4>
                                    <NavLink to={'/berita-selengkapnya/' + MyList.id}>{MyList.berita_title}</NavLink>
                                </h4>
                                <time dateTime={MyList.created_at}>{tanggalFormat}</time>
                            </div>
                        </div>
                    );
                } else {
                    return null; // Lewati rendering jika berita saat ini ditemukan di daftar "Berita Lainnya"
                }
            });

            return (
                <section id="blog" className="blog">
                    <div className="container" data-aos="fade-up">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <article className="blog-details">
                                    <div className="post-img">
                                        <img src={BeritaImage} alt="" className="img-fluid" />
                                    </div>
                                    <h2 className="title">{BeritaTitle}</h2>
                                    <div className="meta-top">
                                        <ul>
                                            <li className="d-flex align-items-center">
                                                <i className="bi bi-person"></i> Admin
                                            </li>
                                            <li className="d-flex align-items-center">
                                                <i className="bi bi-clock"></i>
                                                <time dateTime={CreatedAt}>{tanggalFormat}</time>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="content">{HTMLReactParser(BeritaDescription)}</div>
                                </article>
                            </div>
                            <div className="col-lg-4">
                                <div className="sidebar">
                                    <div className="sidebar-item recent-posts">
                                        <h3 className="sidebar-title">Berita Lainnya</h3>
                                        <div className="mt-4">{MyView}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            );
        } // end else
        else if (this.state.error == true) {
            return <WentWrong />
        } // end if for error
    }
}

export default DetailBerita;