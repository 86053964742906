import React, { Component } from 'react'

class SemuaProgramKerja extends Component {
  render() {
    return (
        <section id="services" class="services sections-bg">
            <div class="container" data-aos="fade-up">
                <div class="row gy-4" data-aos="fade-up" data-aos-delay="100">
                    <div class="col-lg-4 col-md-6">
                        <div class="service-item  position-relative">
                        <div class="icon">
                            <i class="bi bi-people"></i>
                        </div>
                        <h3>Pembinaan Anggota</h3>
                        <p>Membentuk kader pengurus IPMSM yang berjiwa kepemimpinan, adaptif, responsif, tanggung jawab.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="service-item position-relative">
                        <div class="icon">
                            <i class="bi bi-book"></i>
                        </div>
                        <h3>Keagamaan</h3>
                        <p>Pembinaan kemasyarakatan berbasis keagamaan, pengajian bersama masyarakat/remaja, santunan anak yatim dan kaum duafa.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="service-item position-relative">
                        <div class="icon">
                            <i class="bi bi-easel"></i>
                        </div>
                        <h3>Sosial dan Masyarakat</h3>
                        <p>Pendataan masyarakat kurang mampu dan pemberian santunan, kerja sama antar lembaga, kegiatan sosial bencana.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="service-item position-relative">
                        <div class="icon">
                            <i class="bi bi-award"></i>
                        </div>
                        <h3>Seni dan Olahraga</h3>
                        <p>Mengaktifkan kembali olahraga bersama dengan semua pelajar dan mahasiswa se-Sungai Mandau, mengadakan perlombaan seni.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="service-item position-relative">
                        <div class="icon">
                            <i class="bi bi-journal-check"></i>
                        </div>
                        <h3>Pendidikan</h3>
                        <p>Sosialisasi ke SMA tentang Universitas yang ada di Pekanbaru, pembelajaran dan kunjungan.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="service-item position-relative">
                        <div class="icon">
                            <i class="bi bi-chat-square-text"></i>
                        </div>
                        <h3>Komunikasi dan Informasi</h3>
                        <p>Pendataan pengurus IPMSM beserta anggota IPMSM, publikasikan kegiatan rutin IPMSM, publikasi hari-hari besar.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
  }
}

export default SemuaProgramKerja