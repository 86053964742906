import React, { Component } from 'react'
import RestClient from '../RestAPI/RestClient';
import AppUrl from '../RestAPI/AppUrl';
import HTMLReactParser from 'html-react-parser';
import Loading from './Loading';
import WentWrong from './WentWrong';

class Sejarah extends Component {

    constructor(){
        super();
        this.state={
            image:"",
            description:"",
            loading:true,
            error:false
        }
    }
    
    componentDidMount(){
        RestClient.GetRequest(AppUrl.Sejarah).then(result=>{

            if(result == null){
                this.setState({error:true,loading:false})
            }else{
                this.setState({
                    image:result[0]['sejarah_image'],
                    description:result[0]['sejarah_description'],
                    loading:false
                });
            }
        }).catch(error=>{
            this.setState({error:true})
        })
    }

  render() {

    if (this.state.loading == true) {
        return <Loading />
    }
    else if (this.state.error == false) {
        return (
            <section id="profil" class="about">
                <div class="container" data-aos="fade-up">
                    <div class="section-header">
                        <h2>Sejarah</h2>
                        <p>Sejarah Berdirinya Ikatan Pelajar Mahasiswa Sungai Mandau</p>
                    </div>
                    <div class="row gy-4">
                        <div class="col-lg-12">
                            <img src={this.state.image} class="img-fluid rounded-4 mb-4" alt="" />
                            <p>
                                {HTMLReactParser(this.state.description)}
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        )
    } // end else
    else if (this.state.error == true) {
        return <WentWrong />
    } // end if for error
  }
}

export default Sejarah