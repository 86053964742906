import React, { Component } from 'react'
import RestClient from '../RestAPI/RestClient';
import AppUrl from '../RestAPI/AppUrl';
import Loading from './Loading';
import WentWrong from './WentWrong';

class Visi extends Component {

    constructor(){
        super();
        this.state={
            description:"",
            loading:true,
            error:false
        }
    }
    
    componentDidMount(){
        RestClient.GetRequest(AppUrl.Visi).then(result=>{

            if(result == null){
                this.setState({error:true,loading:false})
            }else{
                this.setState({
                    description:result[0]['visi_description'],
                    loading:false
                });
            }
        }).catch(error=>{
            this.setState({error:true})
        })
    }

  render() {

    if (this.state.loading == true) {
        return <Loading />
    }
    else if (this.state.error == false) {
        return (
            <section id="profil" class="about">
                <div class="container" data-aos="fade-up">
                    <div class="section-header">
                        <h2>Visi</h2>
                        <p>Visi Ikatan Pelajar Mahasiswa Sungai Mandau</p>
                    </div>
                    <div class="row gy-4 text-center">
                        <div class="col-lg-12">
                            <p>
                                {this.state.description}
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        )
    } // end else
    else if (this.state.error == true) {
        return <WentWrong />
    } // end if for error
  }
}

export default Visi