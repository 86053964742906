import React, { Component } from 'react'
import RestClient from '../RestAPI/RestClient';
import AppUrl from '../RestAPI/AppUrl';
import Loading from './Loading';
import WentWrong from './WentWrong';

class CallToAction extends Component {

  constructor(){
    super();
    this.state={
        title:"",
        subtitle:"",
        button:"",
        loading:true,
        error:false
    }
  }

  componentDidMount(){
      RestClient.GetRequest(AppUrl.CallToAction).then(result=>{

        if(result == null){
            this.setState({error:true,loading:false})
        }else{
            this.setState({
                title:result[0]['cta_title'],
                subtitle:result[0]['cta_subtitle'],
                button:result[0]['cta_button'],
                loading:false
            });
        }
      }).catch(error=>{
          this.setState({error:true})
      })
  }

  render() {

    if (this.state.loading == true) {
        return <Loading />
    }
    else if (this.state.error == false) {
        return (
            <section id="call-to-action" class="call-to-action">
                <div class="container text-center" data-aos="zoom-out">
                    <h3>{this.state.title}</h3>
                    <p>{this.state.subtitle}</p>
                    <a class="cta-btn" href="#kontak">{this.state.button}</a>
                </div>
            </section>
        )
    } // end else
    else if (this.state.error == true) {
        return <WentWrong />
    } // end if for error
  }
}

export default CallToAction