import React, { Component } from 'react'

class TopBar extends Component {
  render() {
    return (
        <section id="topbar" class="topbar d-flex align-items-center">
            <div class="container d-flex justify-content-center justify-content-md-between">
                <div class="contact-info d-flex align-items-center">
                    <i class="bi bi-envelope d-flex align-items-center"><a href="mailto:ipmsmsungaimandau@gmail.com">ipmsmsungaimandau@gmail.com</a></i>
                    <i class="bi bi-phone d-flex align-items-center ms-4"><span>082289917296</span></i>
                </div>
                <div class="social-links d-none d-md-flex align-items-center">
                    <a href="" class="twitter"><i class="bi bi-twitter"></i></a>
                    <a href="" class="facebook"><i class="bi bi-facebook"></i></a>
                    <a href="" class="instagram"><i class="bi bi-instagram"></i></a>
                    <a href="" class="person-fill"><i class="bi bi-person-fill"></i></a>
                </div>
            </div>
        </section>
    )
  }
}

export default TopBar