import React, { Component } from 'react'
import TopBar from '../components/TopBar';
import NavBar from '../components/NavBar';
import Breadcrumbs from '../components/Breadcrumbs';
import FormKontak from '../components/FormKontak';
import Footer from '../components/Footer';

class KontakKami extends Component {

  componentDidMount(){
    window.scroll(0,0)
  }
  
  render() {
    return (
        <div>
            <TopBar />
            <NavBar title="Kontak | IPMSM" />
            <Breadcrumbs pagetitle="Kontak" pagesubtitle="Jika ada pertanyaan, kritik dan saran. Silahkan hubungi kami dibawah ini." />
            <FormKontak />
            <Footer />
        </div>
    )
  }
}

export default KontakKami