import React, { Component } from 'react'
import loaderIcon from '../../src/assets/image/loader.svg'

class Loading extends Component {
  render() {
    return (
        <div>
            <div class="container text-center">
                <div class="row">
                    <div class="col">
                        <img class="loaderAnimation" src={loaderIcon} />
                    </div>
                </div>
            </div>
        </div>
    )
  }
}

export default Loading