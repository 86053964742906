import React, { Component } from 'react'
import TopBar from '../components/TopBar.jsx'
import NavBar from '../components/NavBar.jsx';
import Hero from '../components/Hero.jsx';
import Profil from '../components/Profil.jsx';
import Summary from '../components/Summary.jsx';
import CallToAction from '../components/CallToAction.jsx';
import ProgramKerja from '../components/ProgramKerja.jsx';
import KataAlumni from '../components/KataAlumni.jsx';
import Pengurus from '../components/Pengurus.jsx';
import BeritaTerbaru from '../components/BeritaTerbaru.jsx';
import Kontak from '../components/Kontak.jsx';
import Footer from '../components/Footer.jsx';

class Beranda extends Component {

  componentDidMount(){
    window.scroll(0,0)
  }
  
  render() {
    return (
        <div>
            <TopBar />
            <NavBar title="IPMSM | Ikatan Pelajar Mahasiswa Sungai Mandau" />
            <Hero />
            <Profil />
            <Summary />
            <CallToAction />
            <ProgramKerja />
            <KataAlumni />
            <Pengurus />
            <BeritaTerbaru />
            <Kontak />
            <Footer />
        </div>
    )
  }
}

export default Beranda