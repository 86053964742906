import React, { Component } from 'react'
import RestClient from '../RestAPI/RestClient';
import AppUrl from '../RestAPI/AppUrl';
import Loading from './Loading';
import WentWrong from './WentWrong';

class Hero extends Component {

    constructor(){
        super();
        this.state={
            title:"",
            subtitle:"",
            link:"",
            image:"",
            loaderClass:"row gy-5",
            mainDivClass:"d-none",
            error:false
        }
    }
    
    componentDidMount(){
        RestClient.GetRequest(AppUrl.Hero).then(result=>{
            if(result == null){
                this.setState({error:true})
            }else{
                this.setState({
                    title:result[0]['hero_title'],
                    subtitle:result[0]['hero_subtitle'],
                    link:result[0]['hero_link'],
                    image:result[0]['hero_image'],
                    loaderClass:"d-none",
                    mainDivClass:"row gy-5"
                });
            } // end else for error
        }).catch(error=>{
            this.setState({error:true})
        });
    }

  render() {

    if (this.state.error == false) {
        return (
            <section id="hero" class="hero">
                <div class={this.state.loaderClass}>
                        <Loading />
                </div>
                <div class="container position-relative">
                    <div class={this.state.mainDivClass} data-aos="fade-in">
                        <div class="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center text-center text-lg-start">
                        <h2>{this.state.title}</h2>
                        <p>{this.state.subtitle}</p>
                        <div class="d-flex justify-content-center justify-content-lg-start">
                            <a href="#profil" class="btn-get-started">Kenalan, yuk!</a>
                            <a href={this.state.link} class="glightbox btn-watch-video d-flex align-items-center"><i class="bi bi-play-circle"></i><span>Lihat Video</span></a>
                        </div>
                        </div>
                        <div class="col-lg-6 order-1 order-lg-2">
                        <img src={this.state.image} class="img-fluid" alt="" data-aos="zoom-out" data-aos-delay="100" />
                        </div>
                    </div>
                </div>
                <div class="icon-boxes position-relative">
                    <div class="container position-relative">
                        <div class="row gy-4 mt-5">
                            <div class="col-xl-3 col-md-6" data-aos="fade-up" data-aos-delay="100">
                                <div class="icon-box">
                                <div class="icon"><i class="bi bi-arrow-repeat"></i></div>
                                <h4 class="title"><a href="" class="stretched-link">Agent of Change</a></h4>
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-6" data-aos="fade-up" data-aos-delay="200">
                                <div class="icon-box">
                                <div class="icon"><i class="bi bi-lightbulb"></i></div>
                                <h4 class="title"><a href="" class="stretched-link">Iron Stock</a></h4>
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-6" data-aos="fade-up" data-aos-delay="300">
                                <div class="icon-box">
                                <div class="icon"><i class="bi bi-mortarboard"></i></div>
                                <h4 class="title"><a href="" class="stretched-link">Moral Force</a></h4>
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-6" data-aos="fade-up" data-aos-delay="500">
                                <div class="icon-box">
                                <div class="icon"><i class="bi bi-gem"></i></div>
                                <h4 class="title"><a href="" class="stretched-link">Guardian of Value</a></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    } // end if error condition
    else if (this.state.error == true) {
        return <WentWrong />
    } // end if for error
  }
}

export default Hero