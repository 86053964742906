import React, { Component } from 'react'
import RestClient from '../RestAPI/RestClient';
import AppUrl from '../RestAPI/AppUrl';
import Loading from './Loading';
import WentWrong from './WentWrong';

class PengurusBidangKomunikasiInformasi extends Component {

    constructor(){
        super();
        this.state={
            myData:[],
            loading:true,
            error:false
        }
    }
    
    componentDidMount(){
        RestClient.GetRequest(AppUrl.PengurusBidangKomunikasiInformasi).then(result=>{

            if(result == null){
                this.setState({error:true,loading:false})
            }else{
                this.setState({myData:result,loading:false});
            }
        }).catch(error=>{
            this.setState({error:true})
        })
    }

  render() {

    if (this.state.loading == true) {
        return <Loading />
    }
    else if (this.state.error == false) {
        const MyList = this.state.myData;
        const MyView = MyList.map(MyList=>{
            return <div class="col-xl-3 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="100">
                        <div class="member">
                        <img src={MyList.pengurus_bidang_komunikasi_informasi_image} class="img-fluid" alt="" />
                        <h4>{MyList.pengurus_bidang_komunikasi_informasi_name}</h4>
                        <span>{MyList.pengurus_bidang_komunikasi_informasi_jabatan}</span>
                        <div class="social">
                            <a href={MyList.pengurus_bidang_komunikasi_informasi_twitter}><i class="bi bi-twitter"></i></a>
                            <a href={MyList.pengurus_bidang_komunikasi_informasi_facebook}><i class="bi bi-facebook"></i></a>
                            <a href={MyList.pengurus_bidang_komunikasi_informasi_instagram}><i class="bi bi-instagram"></i></a>
                            <a href={MyList.pengurus_bidang_komunikasi_informasi_linkedin}><i class="bi bi-linkedin"></i></a>
                        </div>
                        </div>
                    </div>
        })

        return (
            <section id="team" class="team">
                <div class="container" data-aos="fade-up">
                    <div class="section-header">
                        <h2>Bidang Komunikasi & Informasi</h2>
                        <p>Kepala, Sekretaris, Anggota</p>
                    </div>
                    <div class="row gy-4 justify-content-center">
                        {MyView}
                    </div>
                </div>
            </section>
        )
    } // end else
    else if (this.state.error == true) {
        return <WentWrong />
    } // end if for error
  }
}

export default PengurusBidangKomunikasiInformasi