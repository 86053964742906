import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import TopBar from '../components/TopBar';
import NavBar from '../components/NavBar';
import DetailBerita from '../components/DetailBerita';
import Footer from '../components/Footer';
import RestClient from '../RestAPI/RestClient';
import AppUrl from '../RestAPI/AppUrl';

function BeritaDetail() {

    const [BeritaData, setBeritaData] = useState([]);
    const { beritaID } = useParams();

    useEffect(() => {
      window.scroll(0, 0);

      RestClient.GetRequest(AppUrl.BeritaSelengkapnya + beritaID)
        .then(result => {
          setBeritaData(result);
        });
    }, [beritaID]);

    return (
        <div>
            <TopBar />
            <NavBar title="Berita Selengkapnya | IPMSM" />
            <DetailBerita beritaallData={BeritaData} />
            <Footer />
        </div>
    );
}

export default BeritaDetail