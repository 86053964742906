import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { format } from 'date-fns';
import RestClient from '../RestAPI/RestClient';
import AppUrl from '../RestAPI/AppUrl';
import Loading from './Loading';
import WentWrong from './WentWrong';

class SemuaBerita extends Component {

    constructor(){
        super();
        this.state={
            myData:[],
            loading:true,
            error:false
        }
    }
    
    componentDidMount(){
        RestClient.GetRequest(AppUrl.BeritaAll).then(result=>{

            if(result == null){
                this.setState({error:true,loading:false})
            }else{
                this.setState({myData:result,loading:false});
            }
        }).catch(error=>{
            this.setState({error:true})
        })
    }

  render() {

    if (this.state.loading == true) {
        return <Loading />
    }
    else if (this.state.error == false) {
        const MyList = this.state.myData;
        const MyView = MyList.map(MyList=>{
            const tanggalFormat = format(new Date(MyList.created_at), 'dd MMMM yyyy');
            return <div class="col-xl-4 col-md-6">
                        <article>
                        <div class="post-img">
                            <img src={MyList.berita_image} alt="" class="img-fluid" />
                        </div>
                        <h2 class="title mt-4">
                            <NavLink to={"/berita-selengkapnya/"+MyList.id}>{MyList.berita_title}</NavLink>
                        </h2>
                        <div class="d-flex align-items-center">
                            <img src="assets/img/blog/blog-author.jpg" alt="" class="img-fluid post-author-img flex-shrink-0" />
                            <div class="post-meta">
                            <p class="post-author">Admin</p>
                            <p class="post-date">
                                <time datetime={MyList.created_at}>{tanggalFormat}</time>
                            </p>
                            </div>
                        </div>
                        </article>
                    </div>
        })

        return (
            <section id="recent-posts" class="recent-posts sections-bg">
                <div class="container" data-aos="fade-up">
                    <div class="row gy-4 justify-content-center">
                        {MyView}
                    </div>
                </div>
            </section>
        )
    } // end else
    else if (this.state.error == true) {
        return <WentWrong />
    } // end if for error
  }
}

export default SemuaBerita