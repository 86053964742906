import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';

class NavBar extends Component {

  constructor(props){
    super();
    this.state={
      pagetitle:props.title,
    }
  }

  componentDidMount() {

      // Sticky Header on Scroll
      const selectHeader = document.querySelector('#header');

      if (selectHeader) {
      let headerOffset = selectHeader.offsetTop;
      let nextElement = selectHeader.nextElementSibling;

      const headerFixed = () => {
          if ((headerOffset - window.scrollY) <= 0) {
          selectHeader.classList.add('sticked');
          if (nextElement) nextElement.classList.add('sticked-header-offset');
          } else {
          selectHeader.classList.remove('sticked');
          if (nextElement) nextElement.classList.remove('sticked-header-offset');
          }
      };

      window.addEventListener('load', headerFixed);
      document.addEventListener('scroll', headerFixed);
      }
  
      // Mobile nav toggle
      document.querySelectorAll('.mobile-nav-toggle').forEach(el => {
        el.addEventListener('click', this.mobileNavToggle);
      });
  
      // Hide mobile nav on same-page/hash links
      document.querySelectorAll('#navbar a').forEach(navbarlink => {
        if (!navbarlink.hash) return;
  
        let section = document.querySelector(navbarlink.hash);
        if (!section) return;
  
        navbarlink.addEventListener('click', () => {
          if (document.querySelector('.mobile-nav-active')) {
            this.mobileNavToggle();
          }
        });
      });
  }

  mobileNavToggle = () => {
    const body = document.querySelector('body');
    const mobileNavShow = document.querySelector('.mobile-nav-show');
    const mobileNavHide = document.querySelector('.mobile-nav-hide');

    body.classList.toggle('mobile-nav-active');
    mobileNavShow.classList.toggle('d-none');
    mobileNavHide.classList.toggle('d-none');

    // Menutup hamburger menu dengan efek transisi
    const navbarLinks = document.querySelectorAll('.navbar a');
    navbarLinks.forEach(navbarlink => {
      if (body.classList.contains('mobile-nav-active')) {
        navbarlink.style.transitionDelay = '0.1s';
        navbarlink.style.transition = 'transform 0.3s ease-in-out';
        navbarlink.style.transform = 'translateY(0)';
        navbarlink.addEventListener('click', this.mobileNavToggle);
      } else {
        navbarlink.style.transitionDelay = null;
        navbarlink.style.transition = null;
        navbarlink.style.transform = null;
        navbarlink.removeEventListener('click', this.mobileNavToggle);
      }
    });
  };

  render() {
    return (
        <div>
          <title>{this.state.pagetitle}</title>
          <header id="header" class="header d-flex align-items-center">
              <div class="container-fluid container-xl d-flex align-items-center justify-content-between">
                  <div class="logo d-flex align-items-center">
                      <NavLink to="/"><h1>Ipmsm<span>.</span></h1></NavLink>
                  </div>
                  <nav id="navbar" class="navbar">
                      <ul>
                      <li><NavLink to="/">Beranda</NavLink></li>
                      <li><NavLink to="/profil">Profil</NavLink></li>
                      <li><NavLink to="/program-kerja">Program Kerja</NavLink></li>
                      <li><NavLink to="/pengurus">Pengurus</NavLink></li>
                      <li><NavLink to="/berita">Berita</NavLink></li>
                      <li><NavLink to="/kontak">Kontak</NavLink></li>
                      </ul>
                  </nav>
                  <i class="mobile-nav-toggle mobile-nav-show bi bi-list"></i>
                  <i class="mobile-nav-toggle mobile-nav-hide d-none bi bi-x"></i>
              </div>
          </header>
        </div>
    )
  }
}

export default NavBar