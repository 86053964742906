import React, { Component } from 'react'
import TopBar from '../components/TopBar.jsx'
import NavBar from '../components/NavBar.jsx';
import Breadcrumbs from '../components/Breadcrumbs.jsx';
import Sejarah from '../components/Sejarah.jsx';
import Visi from '../components/Visi.jsx';
import Misi from '../components/Misi.jsx';
import Motto from '../components/Motto.jsx';
import Footer from '../components/Footer.jsx';

class ProfilLengkap extends Component {

  componentDidMount(){
    window.scroll(0,0)
  }
  
  render() {
    return (
        <div>
            <TopBar />
            <NavBar title="Profil | IPMSM" />
            <Breadcrumbs pagetitle="Profil" pagesubtitle="Sejarah, Visi, Misi, Motto Ikatan Pelajar Mahasiswa Sungai Mandau" />
            <Sejarah />
            <Visi />
            <Misi />
            <Motto />
            <Footer />
        </div>
    )
  }
}

export default ProfilLengkap