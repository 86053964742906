class AppUrl {

    // static baseURL = 'http://127.0.0.1:8000/api';
    static baseURL = 'https://api-ipmsm.fahrilhadi.com/api';

    static Hero = this.baseURL+'/hero';
    static Profil = this.baseURL+'/profil';
    static Summary = this.baseURL+'/summary';
    static CallToAction = this.baseURL+'/call-to-action';
    static KataAlumni = this.baseURL+'/kata-alumni';
    static Pengurus = this.baseURL+'/pengurus';
    static BeritaHome = this.baseURL+'/berita/home';
    static BeritaAll = this.baseURL+'/berita/all';
    static BeritaSelengkapnya = this.baseURL+'/berita-selengkapnya/';
    static Kontak = this.baseURL+'/kontak';
    static Footer = this.baseURL+'/footer';
    static Sejarah = this.baseURL+'/sejarah';
    static Visi = this.baseURL+'/visi';
    static Misi = this.baseURL+'/misi';
    static Motto = this.baseURL+'/motto';
    static PengurusBesar = this.baseURL+'/pengurus-besar';
    static PengurusBidangPembinaanAnggota = this.baseURL+'/pengurus-bidang-pembinaan-anggota';
    static PengurusBidangSosialMasyarakat = this.baseURL+'/pengurus-bidang-sosial-masyarakat';
    static PengurusBidangKeagamaan = this.baseURL+'/pengurus-bidang-keagamaan';
    static PengurusBidangSeniOlahraga = this.baseURL+'/pengurus-bidang-seni-olahraga';
    static PengurusBidangPendidikan = this.baseURL+'/pengurus-bidang-pendidikan';
    static PengurusBidangKomunikasiInformasi = this.baseURL+'/pengurus-bidang-komunikasi-informasi';
}

export default AppUrl