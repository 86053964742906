import React, { Component } from 'react'
import TopBar from '../components/TopBar'
import NavBar from '../components/NavBar'
import Breadcrumbs from '../components/Breadcrumbs'
import SemuaProgramKerja from '../components/SemuaProgramKerja'
import Footer from '../components/Footer'

class SemuaProker extends Component {

  componentDidMount(){
    window.scroll(0,0)
  }
  
  render() {
    return (
        <div>
            <TopBar />
            <NavBar title="Program Kerja | IPMSM" />
            <Breadcrumbs pagetitle="Program Kerja" pagesubtitle="Program Kerja Ikatan Pelajar Mahasiswa Sungai Mandau Periode 2022-2024" />
            <SemuaProgramKerja />
            <Footer />
        </div>
    )
  }
}

export default SemuaProker