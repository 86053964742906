import React, { Component } from 'react'
import RestClient from '../RestAPI/RestClient';
import AppUrl from '../RestAPI/AppUrl';
import Loading from './Loading';
import WentWrong from './WentWrong';

class Misi extends Component {

    constructor(){
        super();
        this.state={
            myData:[],
            loading:true,
            error:false
        }
    }
    
    componentDidMount(){
        RestClient.GetRequest(AppUrl.Misi).then(result=>{

            if(result == null){
                this.setState({error:true,loading:false})
            }else{
                this.setState({myData:result,loading:false});
            }
        }).catch(error=>{
            this.setState({error:true})
        })
    }

  render() {

    if (this.state.loading == true) {
        return <Loading />
    }
    else if (this.state.error == false) {
        const MyList = this.state.myData;
        const MyView = MyList.map(MyList=>{
            return <li><i class="bi bi-check-circle-fill"></i> {MyList.misi_description}</li>
        })

        return (
            <section id="profil" class="about">
                <div class="container" data-aos="fade-up">
                    <div class="section-header">
                        <h2>Misi</h2>
                        <p>Misi Ikatan Pelajar Mahasiswa Sungai Mandau</p>
                    </div>
                    <div class="row gy-4 mt-5">
                        <div class="col-lg-12">
                            <div class="content ps-0 ps-lg-5">
                                <ul>
                                    {MyView}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    } // end else
    else if (this.state.error == true) {
        return <WentWrong />
    } // end if for error
  }
}

export default Misi