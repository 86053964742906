import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import RestClient from '../RestAPI/RestClient';
import AppUrl from '../RestAPI/AppUrl';
import Loading from './Loading';
import WentWrong from './WentWrong';

class Footer extends Component {

    constructor(){
        super();
        this.state={
            title:"...",
            description:"",
            twitter:"",
            facebook:"",
            instagram:"",
            loaderClass:"col-lg-5 col-md-12 footer-info",
            mainDivClass:"d-none",
            error:false
        }
    }
    
    componentDidMount(){
        RestClient.GetRequest(AppUrl.Footer).then(result=>{

            if(result == null){
                this.setState({error:true})
            }else{
                this.setState({
                    title:result[0]['footer_title'],
                    description:result[0]['footer_description'],
                    twitter:result[0]['footer_twitter'],
                    facebook:result[0]['footer_facebook'],
                    instagram:result[0]['footer_instagram'],
                    loaderClass:"d-none",
                    mainDivClass:"col-lg-5 col-md-12 footer-info"
                });
            } // end else for error
        }).catch(error=>{
            this.setState({error:true})
        })
    }

  render() {

    if (this.state.error == false) {
        let currentYear = new Date().getFullYear()

        return (
            <footer id="footer" class="footer">
                <div class="container">
                    <div class="row gy-4">
                        <div class={this.state.loaderClass}>
                            <Loading />
                        </div>
                        <div class={this.state.mainDivClass}>
                            <a href="index.html" class="logo d-flex align-items-center">
                                <span>{this.state.title}</span>
                            </a>
                            <p>{this.state.description}</p>
                            <div class="social-links d-flex mt-4">
                                <a href={this.state.twitter} class="twitter"><i class="bi bi-twitter"></i></a>
                                <a href={this.state.facebook} class="facebook"><i class="bi bi-facebook"></i></a>
                                <a href={this.state.instagram} class="instagram"><i class="bi bi-instagram"></i></a>
                            </div>
                        </div>
                        <div class="col-lg-2 col-6 footer-links">
                            <h4>Informasi</h4>
                            <ul>
                                <li><NavLink to="/">Beranda</NavLink></li>
                                <li><NavLink to="/profil">Profil</NavLink></li>
                                <li><NavLink to="/program-kerja">Program Kerja</NavLink></li>
                                <li><NavLink to="/pengurus">Pengurus</NavLink></li>
                                <li><NavLink to="/berita">Berita</NavLink></li>
                            </ul>
                        </div>
                        <div class="col-lg-4 col-md-12 footer-contact text-center text-md-start">
                            <h4>Sekretariat</h4>
                            <p>
                                Jl. Melati Sakti <br />
                                Perumahan Setia Mulia III <br />
                                Pengembangan Blok D6 Pekanbaru<br /><br />
                                <strong>Telp/Wa:</strong> 082289917296<br />
                                <strong>Email:</strong> ipmsmsungaimandau@gmail.com
                            </p>
                        </div>
                    </div>
                </div>
                <div class="container mt-4">
                    <div class="copyright">
                        Copyright &copy; 2023 - {currentYear}. All Rights Reserved.
                    </div>
                    <div class="credits">
                        {/* All the links in the footer should remain intact.
                        You can delete the links only if you purchased the pro version.
                        Licensing information: https://bootstrapmade.com/license/
                        Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/impact-bootstrap-business-website-template/ */}
                        Website Develop by <a href="https://fahrilhadi.com" target="_blank" rel="noreferrer">Fahril Hadi</a> <br />
                        Designed by <a href="https://bootstrapmade.com/" target="_blank" rel="noreferrer">BootstrapMade</a>
                    </div>
                </div>
            </footer>
        )
    } // end if error condition
    else if (this.state.error == true) {
        return <WentWrong />
    } // end if for error
  }
}

export default Footer