import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

class Breadcrumbs extends Component {
  render() {
    return (
        <div class="breadcrumbs">
            <div class="page-header d-flex align-items-center">
                <div class="container position-relative">
                <div class="row d-flex justify-content-center">
                    <div class="col-lg-6 text-center">
                    <h2>{this.props.pagetitle}</h2>
                    <p>{this.props.pagesubtitle}</p>
                    </div>
                </div>
                </div>
            </div>
            <nav>
                <div class="container">
                <ol>
                    <li><NavLink to="/">Beranda</NavLink></li>
                    <li>{this.props.pagetitle}</li>
                </ol>
                </div>
            </nav>
        </div>
    )
  }
}

export default Breadcrumbs